.container {
  max-width: 32rem;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: grid;
  /* row/column template count is added by the component */
  gap: 0.125rem;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
  /* row/column position/span is added by the component */
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.1);
}

.cell:hover {
  border: 4px solid var(--foreground);
}
