.palette {
  /* It's a CSS grid with two equal columns, and rows that are as tall as the columns are wide. */
  width: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 0.125rem;
}

.color {
  /* The color divs are flexboxes with a centered content. */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid var(--background);
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}

.color:hover {
  border: 4px solid rgba(255, 255, 255, 0.3);
}

.color.active {
  border: 4px solid var(--foreground);
}
