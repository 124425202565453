.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: start;
  gap: 2rem;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}